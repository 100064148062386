
// TODO

const url = {
	host: "utility.negosyonow.com",
	protocol: "https",

	suffix: ""
};

module.exports = {
	url
}

