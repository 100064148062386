
import React from "react"

import ProductBasicEditorPage from "../../components/negosyonow/controls/productbasiceditor"


const onsorganizationpickerfields = [
	{"label":"Name", "dbfield": "onsorganization_name", "type": "text", "filtertype": "textbox"},
	{"label":"Alias", "dbfield": "onsorganization_shortname", "type": "text", "filtertype": "textbox"}
];

const onsorganizationpickerfilter = [
	//1 = negosyonow
	{"field":"onsorganization_branchid", "operation": ">", "value": 1},
];

const nnproductaliaspickerfields = [
	{"label":"Product Line", "dbfield": "nnproductalias_name", "type": "text", "filtertype": "textbox"},
	{"label":"Product Name", "dbfield": "nnproductalias_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nnproductalias_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproductalias_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Last Update", "dbfield": "nnproductalias_lastupdate", "type": "datetime", "filtertype": "datetime"},
];

const nnproductaliaspickerfilter = [
];


const nnbrandpickerfields = [
	{"label":"Name", "dbfield": "nnbrand_name", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nnbrand_image", "type": "image", "filtertype": "image"},
];

const nnbrandpickerfilter = [
];


const nntagpickerfields = [
	{"label":"Name", "dbfield": "nntag_name", "type": "text", "filtertype": "textbox"},
];

const nntagpickerfilter = [
	{"field":"nntagalias_id", "operation": "<>", "value": "48"},	// Page
	{"field":"nntagalias_id", "operation": "<>", "value": "1"},		// Brand
	{"field":"nntag_id", "operation": "<>", "value": "99"},			// Promo
	{"field":"nntag_official", "operation": "=", "value": "1"},
	{"field":"nntag_url", "operation": "<>", "value": ""}
];


const nntagexclusivepickerfields = [
	{"label":"Name", "dbfield": "nntagexclusive_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "nntagexclusive_description", "type": "text", "filtertype": "textbox"}
];

const nntagexclusivepickerfilter = [
];


const searchFields = [
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"Seller", "dbfield": "onsorganization_name", "type": "text", "filtertype": "textbox"},
	{"label":"Seller SKU", "dbfield": "nnproduct_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Full Name / Display", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Active", "dbfield": "nnproduct_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Permanently Delisted/Internal Use", "dbfield": "nnproduct_internal", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Price", "dbfield": "nnproduct_price", "type": "currency", "filtertype": "currency"},
	{"label":"Promo Item", "dbfield": "nnproduct_promoitem", "type": "checkbox", "filtertype": "checkbox"},

	{"label":"Brand", "dbfield": "nnbrand_name", "type": "text", "filtertype": "textbox"},
	{"label":"Product Line", "dbfield": "nnproductalias_name", "type": "text", "filtertype": "textbox"},

	{"label":"Last Update", "dbfield": "nnproduct_lastupdate", "type": "datetime", "filtertype": "datetime"},
];


const basicFields = [
	{"label":"Primary Image", "field": "nnproduct_image", "value": "", "input": "image", "mode": "readonly"},
	{"label":"Seller", "field": "onsorganization_name", "value": "", "input": "picker", "mode": "required", "pickerfields": onsorganizationpickerfields, "pickerfilters": onsorganizationpickerfilter},
	{"label":"Seller SKU", "field": "nnproduct_orgsku", "value": "", "input": "textbox", "mode": "editonce"},
	{"label":"Full Name / Display", "field": "nnproduct_fullname", "value": "", "input": "textbox", "mode": "required"},

	{"label":"Brand", "field": "nnbrand_name", "value": "", "input": "picker", "mode": "required",  "pickerfields": nnbrandpickerfields, "pickerfilters": nnbrandpickerfilter},
	{"label":"Product Line", "field": "nnproduct_name", "value": "", "input": "textbox", "mode": "required", "info":"Internal name used for searching/tagging parent products"},
	{"label":"Parent Product", "field": "nnproductalias_fullname", "value": "", "input": "picker", "mode": "normal",  "pickerfields": nnproductaliaspickerfields, "pickerfilters": nnproductaliaspickerfilter},

	{"label":"Variant Display", "field": "nnproduct_subname", "value": "", "input": "textbox", "mode": "normal", "info":"Displayed under product variant options in website"},
	//{"label":"Variant Group", "field": "nnproductalias_id", "value": "", "input": "hidden", "mode": "readonly"},

	{"label":"Has Page", "field": "nnproduct_haspage", "value": "", "input": "hidden", "mode": "readonly"},
	{"label":"Webpage", "field": "nnproduct_actualurl", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/"},
	{"label":"Last Updated", "field": "nnproduct_lastupdate", "value": "", "input": "updatetime", "mode": "readonly"},
	{"label":"Active", "field": "nnproduct_active", "value": "", "input": "checkbox", "mode": "normal"},
	{"label":"Price", "field": "nnproduct_price", "value": "", "input": "currency", "mode": "required"},
	{"label":"Promo Item", "field": "nnproduct_promoitem", "value": "", "input": "checkbox", "mode": "normal"},
	{"label":"Promo Image", "field": "nnproduct_promoimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":500, "imageheight":400, "imageformat":"jpg", "uploadtype":"https", "filehost": "cdn.ngnw.ph", "filelocation": "images/product/promo", "info":"For website promo display area"},


	{"label":"Write-up", "field": "nnproduct_description", "value": "", "input": "textarea", "mode": "normal"},
	{"label":"Teaser/Summary", "field": "nnproduct_summary", "value": "", "input": "textarea", "mode": "normal"},
];

const productDimensionfields = [
	{"label":"Weight (Kilograms)", "field": "nnproduct_pkgweight", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Length (cm)", "field": "nnproduct_pkglength", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Width (cm)", "field": "nnproduct_pkgwidth", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Height (cm)", "field": "nnproduct_pkgheight", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Volume (Liters)", "field": "nnproduct_pkgvolume", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Irregular/Not Boxed", "field": "nnproduct_pkgirregular", "value": "", "input": "checkbox", "mode": "required"},
	{"label":"Storage Instructions", "field": "nnproductstorage_id", "value": "", "input": "combo", "mode": "required", "options": [
		{"value":1,"display":"N/A"},
		{"value":2,"display":"Cool Dry Place"},
		{"value":3,"display":"Refrigerated"},
		{"value":4,"display":"Frozen"},
	]},
	{"label":"Shelf Life", "field": "nnproduct_shelflife", "value": "", "input": "textbox", "mode": "normal"},
];


const subformFields = [
	{
		"subformid": "nnproductimage",
		"label": "Images",
		"table": "nnproductimage",
		"sort": "nnproductimage_primary desc",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Image",
				"field": "nnproductimage_url",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "required",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "images/product/main/full", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "fit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 2048,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 2048,	// OPTIONAL: Will be required for some imagemode
			},
			{
				"label": "Description",
				"field": "nnproductimage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
			{
				"label": "Main Imaage",
				"field": "nnproductimage_primary",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal",
				"info": "Only one main image"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnproducttag",
		"label": "Categories",
		"table": "nnproducttag",
		"sort": "nnproducttag_id ",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Category",
				"field": "nntag_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nntagpickerfields,
				"pickerfilters": nntagpickerfilter
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnproductpricing",
		"label": "Franchise/Special Pricing",
		"table": "nnproductpricing",
		"sort": "nnproductpricing_id ",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Type",
				"field": "nntagexclusive_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nntagexclusivepickerfields,
				"pickerfilters": nntagexclusivepickerfilter
			},
			{
				"label": "Price",
				"field": "nnproductpricing_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "required"
			}
		],
		"footerlist": [
		]
	}
];

const ProductPage = ({location}) => {


	return <ProductBasicEditorPage
				allowadd={true}
				location={location}
				formFields={[basicFields.concat(productDimensionfields)]}
				searchFields={searchFields}
				editSubFormFields={subformFields}
			/>
}


export default ProductPage;
