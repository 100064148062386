

const apiconfig = require("../config/negosyonow-config");
const requestJSON = require("./requestJSON");

const getURL = (entity, action) => {
	return apiconfig.url.protocol+"://"+apiconfig.url.host+"/"+action+"/"+entity;
}


const buildOrder = (jwtToken, type, params) => {
	return requestJSON.send( getURL(type.toLowerCase(), "databuild") , "POST", params, jwtToken);
}

const buildEntityId = (pkid, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"id", "databuild") , "POST",{"id": pkid}, jwtToken);
}

const buildEntityIdList = (idlist, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"idlist", "databuild") , "POST",{"idlist": idlist}, jwtToken);
}


const buildEntitybyParam = (param, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"id", "databuild") , "POST",param, jwtToken);
}


const buildEntityIdListbyParam = (param, jwtToken, entity) => {
	return requestJSON.send( getURL(entity.toLowerCase()+"idlist", "databuild") , "POST",param, jwtToken);
}

const loadFormData = (url, jwtToken) => {
	// Retrieves submission payload (e.g. seminar registration)
	return requestJSON.send( getURL("json", "loadform") , "POST",{"url": url}, jwtToken);
}

const activateEntity = (pkid, jwtToken, entity) => {
	// Should trigger corresponding build
	return requestJSON.send( getURL(entity, "activate") , "POST",{"documentid": pkid}, jwtToken);
}


const queryData = (entity, params, jwtToken) => {
	// Internal
	/*
	// Sample
	const params = {
		*tablelist: [],
		fieldlist: [],
		*joinlist: [{table:"", condlist:[], *type:""}],
		*filters:  [[{field:"", value:"", *operation:""}]],
		*condlist: [],
		*grouping: {fieldlist:[], condlist:[]}
		*sortlist: [],

		*getallrows: false,
		*count: 20,
		*offset: 0
	};
	*/
	return requestJSON.send( getURL(entity, "query")+"/data" , "POST", params, jwtToken);
}

const getUserLocation = (jwtToken, callback) => {
	queryData("nnsellerlocation", {
			fieldlist:["NNPICKUP.nnpickup_id", "NNPICKUP.nnpickup_name"],
			joinlist: [{table:"nnpickup", condlist:["NNPICKUP.nnpickup_id=NNSELLERLOCATION.nnpickup_id"], type:"left"}],
			count:1
		}, jwtToken).then(response => {
			if (response.status === "OK") {
				if (response.records.length > 0) {
					callback(response.records[0].nnpickup_id, response.records[0].nnpickup_name);
					return;
				}
				callback(-1, "");
				//callback(0, "");
				return;
			}
			//callback(0, "");
			callback(-2, "");
		});
}

const getBookingStatusOption = (onsorderformfulfillmentid, bookingstatusid, encodertype = 0) => {
	// 0 - All, 1 - Seller, 2 - Courier, 3 - NN Coordinator, 4 - Customer Support

	const staticstatuslist = [
		"",
		"Ordered",
		"Dispatched",
		"Completed",
		"For Customer Approval",
		"Cancelled",
		"For re-scheduling",
		"Accepted/For dispatching",
		"Packed",
		"For scheduling (courier)",
		"For coordination (e.g. bus shipping)",
		"Draft",
		"Reschedule Pick up/Delivery"
	];
	if (bookingstatusid<1 || bookingstatusid >= staticstatuslist.length) {
		return [];
	}
	const courierfinalizeoption = (onsorderformfulfillmentid > 3 && bookingstatusid !== 10) ? {"value":10,"display":"For Coordination"} : {"value":4,"display":"For Customer Approval"};
	const baseoption = {"value":bookingstatusid ,"display":staticstatuslist[bookingstatusid]};

	if (bookingstatusid === 1 || bookingstatusid === 6) {
		// Ordered (1) or Reschedule (6)
		if (encodertype === 1 || encodertype === 4) {
			// Seller (1) or Customer Support (4)
			return [
				baseoption,
				{"value":4,"display":encodertype===1?"Allocated":"For Customer Approval"},
				// Stopgap options
				//{"value":8,"display":"Packed"},
				//{"value":2,"display":"Dispatched"}
			];
		} else if (encodertype === 2 || encodertype === 3) {
			// Courier / Coordinator
			// TODO: No finalize when live
			return [
				baseoption,
				// Stopgap options
				courierfinalizeoption
			];
		}
	} else if (bookingstatusid === 11) {
		// Draft (1)
		if (encodertype === 4) {
			// Customer Support (4)
			return [
				baseoption,
				{"value":1,"display":"Ordered"}
			];
		}
	} else if (bookingstatusid === 12) {
		// Should not happen only because of system auto-set documents past target date
		// Reschedule Pick up/Delivery (12)
		if (encodertype === 4) {
			// Customer Support (4)
			return [
				baseoption,
				{"value":2,"display":"New Pick up/Delivery Schedule"},
				{"value":3,"display":"Completed"},
				{"value":5,"display":"Cancelled"},
			];
		}
	} else if (bookingstatusid === 2) {
		// Dispatched (2)
		if (encodertype === 4) {
			// Customer Support (4)
			return [
				baseoption,
				{"value":12,"display":"Reschedule Pick up/Delivery"},
				{"value":3,"display":"Completed"},
				{"value":5,"display":"Cancelled"},
			];
		}
	} else if (bookingstatusid === 7 || bookingstatusid === 8) {
		// Accepted/For dispatching (7) or Packed (8)
		if (encodertype === 1) {
			// Seller
			if (bookingstatusid === 8) {
				return [
					baseoption,
					{"value":2,"display":"Dispatched to drop off location"}
				];
			} else {
				return [
					baseoption,
					{"value":8,"display":"Packed"},
					{"value":2,"display":"Dispatched to drop off location"}
				];
			}
		} else if (encodertype === 4) {
			// Customer Support
			return [
				baseoption,
				{"value":6,"display":"For review/re-scheduling"},
				{"value":2,"display":"Dispatched by Seller"},
			];
		}
	} else if (bookingstatusid === 4) {
		// For customer approval (allocated and scheduled)
		if (encodertype === 4) {
			// Customer Support
			return [
				baseoption,
				{"value":7,"display":"Accepted"},
				{"value":6,"display":"For review/re-scheduling"},
				//{"value":5,"display":"Cancel"} //added by default
			];
		}
	} else if (bookingstatusid === 9 || bookingstatusid === 10) {
		// Fos scheduling courier (9) or bus shipping (10)
		if (encodertype !== 1) {
			return [
				baseoption,
				courierfinalizeoption
			];
		}
	}
	// Others; no editing, also for Dispatched (2), Completed (3), Cancelled (5)
	return [baseoption];
}


module.exports = {
	buildOrder,
	buildEntityId,
	buildEntityIdList,
	buildEntitybyParam,
	buildEntityIdListbyParam,
	loadFormData,
	getUserLocation,
	getBookingStatusOption,
	activateEntity
}
